import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { t } from '../helpers/language';
import ModuleArea from '../helpers/moduleArea';
import { FaPhone } from 'react-icons/fa';
import { Waypoint } from 'react-waypoint';
import {
  Intro,
  Swirl,
  Section,
  Prefooter,
  ProductIntro,
  FeaturedCard,
  Swatches,
  Carousel,
  CardList,
  Card,
  Specs,
  Compare,
  Recommendation
} from '../partials/components';
import { PageTransition } from '../helpers/pageTransitions';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    };
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    const {
      data: { product, globalConfig },
      pageContext,
      path,
      location
    } = this.props;
    const compareProducts = product.productComparison;

    const { contactPage, wetTestPage, defaultPathways } = globalConfig;

    const sectionNav = [
      {
        text: t('overview_section_label'),
        id: `overview`
      },
      {
        text: t('design_section_label'),
        id: `features`
      },
      {
        text: t('specifications_section_label'),
        id: `specifications`
      },
      {
        text: t('compare_section_label'),
        id: `compare`
      }
    ];

    const overview = {
      heading: 'Overview',
      caption: product.overview,
      images: product.gallery,
      links: [
        {
          path: `${contactPage.path}`,
          gaTarget: 'product-enquiry-cta',
          state: {
            type: 'contact',
            product: product
          },
          title: t('spaworld_contact_cta')
        },
        {
          path: `${wetTestPage.path}`,
          gaTarget: 'product-wet-test-cta',
          state: {
            type: 'wet-test',
            product: product
          },
          title: t('spaworld_wet_test_cta')
        }
      ]
    };

    const productFeatures = product.productFeatures.map(feature => ({
      ...feature,
      padding: 'Both',
      background: 'None'
    }));

    const allPathways = [...product.pathways, ...defaultPathways].slice(0, 3);

    const showVariants = product.productVariants.length > 1;

    if (showVariants) {
      sectionNav.splice(1, 0, {
        text: t('colours_section_label'),
        id: `options`
      });
    }

    return (
      <PageTransition>
        <HelmetDatoCms seo={product.seoMetaTags} />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
          <div>
            <Section id="product-top">
              <Intro
                title={product.title}
                breadcrumbs={pageContext.breadcrumbs}
                action={{
                  iconAfter: <FaPhone />,
                  children: t('price_cta'),
                  gaTarget: 'product-phone-cta',
                  to: `tel:${t('phone_number')}`
                }}
              >
                <Swirl />
              </Intro>
            </Section>
            <Section animate={true} padding={'double-top'}>
              <Recommendation productId={product.id} />
              <ProductIntro {...product} sectionNav={sectionNav} />
            </Section>

            <Section id="overview" animate={true}>
              <FeaturedCard {...overview} />
            </Section>
          </div>
        </Waypoint>

        {showVariants && (
          <Section
            id="options"
            heading={t('colour_heading')}
            lead={product.coloursDescription}
            background={'dark-gradient'}
            padding={'double'}
          >
            <Swatches variants={product.productVariants}></Swatches>
          </Section>
        )}

        <Section
          id="features"
          heading={`${product.title} Features`}
          lead={product.featuresDescription}
          padding={'double'}
        >
          {productFeatures && (
            <ModuleArea modules={productFeatures} location={location} />
          )}

          {product.featuresCarousel.length > 0 && (
            <Carousel moduleStyle="Features" items={product.featuresCarousel} />
          )}
        </Section>

        <Section padding={'both'}>
          <CardList fullWidth={true}>
            {allPathways.map((pathway, i) => (
              <Card key={`pathway-${i}`} {...pathway} />
            ))}
          </CardList>
        </Section>

        <Section
          id="specifications"
          heading={t('specs_heading')}
          lead={product.specificationsDescription}
          padding={'both'}
        >
          <Specs {...product} />
        </Section>

        {product.productComparison && (
          <Section
            id="compare"
            heading={t('compare_heading')}
            lead={product.compareDescription}
            background="light-gray"
            padding={'double'}
          >
            <Compare
              product={product}
              compareProducts={compareProducts}
              contactPagePath={contactPage.path}
              path={path}
            />
          </Section>
        )}
        <Section background="light-gray">
          <Prefooter contactPagePath={contactPage.path} />
        </Section>
      </PageTransition>
    );
  }
}

export const query = graphql`
  query productById($id: String, $language: String) {
    product: datoCmsProduct(id: { eq: $id }) {
      id
      slug
      ...InfusionsoftTags
      ...ProductIntro
      ...Specs

      priceRange
      volume
      dimensions
      lounge
      seating
      jets
      length
      width

      warrantyDocument {
        url
        alt
        title
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      overview

      featuresDescription
      productFeatures {
        ... on DatoCmsContentWithImage {
          ...ContentWithImage
        }
        ... on DatoCmsContentWithVideo {
          ...ContentWithVideo
        }
        ... on DatoCmsContentWithCarousel {
          ...ContentWithCarousel
        }
      }

      coloursDescription
      ...Swatches

      compareDescription
      productComparison {
        ...Compare
      }

      featuresCarousel {
        ...Slide
      }
      gallery {
        fluid(
          maxWidth: 1600
          maxHeight: 660
          imgixParams: { fit: "crop", w: "1600", h: "660" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }

      pathways {
        ...Card
      }
    }

    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      wetTestPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`;

export default Product;
